import { useContext, useEffect, useState } from "react"
import CommandCenterService from "../../../service/CommandCenterService"
import useUrlParams from "./useUrlParams"
import { toast } from "react-toastify"
import { navigateTo } from "../../CommonComponents/CommonFunctions"
import { useNavigate } from "react-router-dom"
import { ScenarioContext } from "../providers/ScenarioProvider"

const useTrueTwin = () => {
    const navigate = useNavigate()
    const context = useContext(ScenarioContext);
    const { mission_name, mission_id, sat_name, sat_id, truetwin_name, truetwin_id, dashboard } = useUrlParams();

    const [loading, setLoading] = useState<boolean>(true)
    const [remoteAppConnected, setRemoteAppConnected] = useState<boolean>(false);
    const [category, setCategory] = useState<string>('')

    if (context === undefined) {
        throw new Error('useScenario must be used within a ScenarioProvider');
    }
    const { unDeployLoader, setUnDeployLoader, TrueTwin, setTrueTwin } = context;

    const navigate_to_truetwin_details = () => {
        navigateTo(
            `${mission_name}/${mission_id}/${sat_name}/${sat_id}/truetwin_details/${truetwin_name}/${truetwin_id}/${dashboard === 'quick-deploy' ? 'Configuration' : 'Scenarios'}`,
            navigate
        );
    }

    const get_truetwin_status = () => {
        if (TrueTwin?.['isDeployed'] === 'Yes') {
            setUnDeployLoader({ 'retiring': `Retiring ${TrueTwin?.['Category']} ${truetwin_name && truetwin_name?.length > 15 ? truetwin_name?.substring(0, 15) + "..." : truetwin_name}` });
            CommandCenterService.getTrueTwinData(truetwin_id).then(res => {
                if (res?.data) {
                    if (res?.data?.['isDeployed'] === 'Yes') {
                        setTimeout(() => {
                            get_truetwin_status()
                        }, 10000)
                    } else {
                        setTrueTwin(res?.data)
                        navigate_to_truetwin_details()
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        } else {
            navigate_to_truetwin_details()
        }
    }

    useEffect(() => {
        if (TrueTwin) {
            const remote_app_connected = TrueTwin?.['isRemoteAppConfigured'] === 'Yes' ? true : false
            const category = TrueTwin?.['Category']
            setRemoteAppConnected(remote_app_connected)
            setCategory(category)
        }
    }, [TrueTwin])

    const undeploy_truetwin = () => {
        CommandCenterService.unDeployTrueTwin(truetwin_id)?.then((res) => {
            if (res) {
                if (TrueTwin?.['isRemoteAppConfigured'] === 'Yes') {
                    // dispatch(
                    //     setRemoteAppStatus({
                    //         status: 0,
                    //         truetwin_id: '',
                    //     })
                    // );
                }
                setUnDeployLoader(false)
                navigate_to_truetwin_details()
            }
        }).catch((err) => {
            setUnDeployLoader({ 'error': `An error occurred while retiring ${TrueTwin?.['Category']} ${truetwin_name && truetwin_name?.length > 15 ? truetwin_name?.substring(0, 15) + "..." : truetwin_name}` });
            toast.error('Something went wrong while undeploying truetwin');
        });
    };

    const dis_connect_ground_station = () => {
        if (TrueTwin?.['isDeployed'] === 'Yes') {
            setUnDeployLoader({ 'retiring': `Retiring ${TrueTwin?.['Category']} ${truetwin_name && truetwin_name?.length > 15 ? truetwin_name?.substring(0, 15) + "..." : truetwin_name}` });
            CommandCenterService.disconnectGroundStation(TrueTwin?.["groundStationId"], truetwin_id)?.then((res) => {
                if (res.data) {
                    undeploy_truetwin();
                }
            }).catch((err) => {
                undeploy_truetwin();
                console.error(err);
            });
        } else {
            navigate_to_truetwin_details()
        }
    };

    return {
        loading,
        TrueTwin,
        category,
        remoteAppConnected,
        unDeployLoader,
        get_truetwin_status,
        dis_connect_ground_station,
    };

}

export default useTrueTwin