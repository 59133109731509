import useUrlNavigation from '../hooks/useUrlNavigation'
import './ErrorPage.scss'
import { ErrorPageSvg } from './Svgs'

const ErrorPage = ({ error_code }: any) => {
    const { navigate_dashboard } = useUrlNavigation()

    return (
        <div className='error__page__container'>
            <div className='error__page__svg__container'>
                <ErrorPageSvg />
            </div>
            <div className='error__page__content__container'>
                <div className='error__page__content__title'>{error_code}</div>
                <div className='error__page__content__description'>
                    Oops! Something went wrong, please try again later.
                </div>
                <button className='error__page__back__button' onClick={navigate_dashboard}>
                    Return Home
                </button>
            </div>
        </div>
    )
}

export default ErrorPage