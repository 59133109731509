// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import 'mapbox-gl/dist/mapbox-gl.css';

import * as MAP_CONSTANTS from "../MissionModelling.constants";
import * as mapActions from "../../../store/MapSlice";
import * as mmActions from "../../../store/MissionModellingSlice";
import { getEnvVar } from "../../../envUtils";

const MapboxView = () => {

    mapboxgl.accessToken = getEnvVar('REACT_APP_MAPBOX_TOKEN') // Mapbox access token for Equirectangular projection
    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const mapContainerRef = useRef(null);

    const aoiCollection = useSelector(state => state.missionModelling.aoiCollection);
    const selectedFeature = useSelector(state => state.missionModelling.selectedFeature);
    const editAreaOfInterestObject = useSelector(state => state.missionModelling.editAreaOfInterestObject);

    const dispatch = useDispatch();

    const removeSourceAndLayers = (sourceId, layerIds) => {
        const newMap = mapRef.current;
        // Check if the source already exists, and remove it along with its layers
        if (newMap.getSource(sourceId)) {
            layerIds.forEach((layerId) => {
                if (newMap.getLayer(layerId)) {
                    newMap.removeLayer(layerId);
                }
            });
            newMap.removeSource(sourceId);
        }
    };

    useEffect(() => {
        // to init the map
        if (mapRef.current) return;

        if (!mapRef.current) {
            const initializeMap = () => {

                const newMap = new mapboxgl.Map({
                    container: 'map',
                    style: getEnvVar('REACT_APP_MAPBOX_STYLE_URL'), // Mapbox style url for Equirectangular projection
                    center: MAP_CONSTANTS.MAP_CENTER,
                    zoom: MAP_CONSTANTS.MAP_DEFAULT_ZOOM,
                    minZoom: MAP_CONSTANTS.MAP_MIN_ZOOM,
                });

                newMap.on('load', () => {

                    // to allow drawing aois
                    const drawControl = new MapboxDraw({
                        displayControlsDefault: false,
                        userProperties: true,
                        modes: {
                            ...MapboxDraw.modes,
                        },
                    });

                    newMap.addControl(drawControl);

                    // dispatch(mapActions.setMapRef(newMap));
                    dispatch(mapActions.setDrawRef(drawControl));
                    drawRef.current = drawControl;

                    const updateCoordinates = () => {
                        if (drawControl) {
                            const drawnFeatures = drawControl.getAll();
                            if (drawnFeatures && drawnFeatures.features.length > 0) {
                                const featureInfo = {
                                    coordinates: drawnFeatures.features[0].geometry.coordinates,
                                    geometry: drawnFeatures.features[0].geometry,
                                }
                                dispatch(mapActions.setDrawnFeatureInfo(featureInfo));
                            }
                        }
                    };

                    newMap.on('draw.create', updateCoordinates);
                    newMap.on('draw.update', updateCoordinates);

                    // cleanup function to remove event listeners and controls
                    return () => {
                        newMap.off('draw.create', updateCoordinates);
                        newMap.off('draw.update', updateCoordinates);
                        if (drawControl) {
                            drawControl.deleteAll();
                            newMap.removeControl(drawControl);
                        }
                        newMap.remove();
                    };
                });

                dispatch(mapActions.setMapRef(newMap));
                mapRef.current = newMap;
            };

            initializeMap();

            return () => {
                if (mapRef.current) {
                    mapRef.current.remove();
                    mapRef.current = null;
                }
            };
        }
    }, [dispatch]);

    const featureClickHandler = (e) => {
        const clickedFeature = mapRef.current.queryRenderedFeatures(e.point, {
            layers: ['aoi-polygon', 'aoi-point'],
        });
        dispatch(mmActions.setSelectedFeature(clickedFeature[0]));
    }

    const showAoiOnMap = () => {
        const newMap = mapRef.current;
        const sourceId = 'aoi-source';
        const layersToRemove = ['aoi-polygon', 'aoi-polygon-outline', 'aoi-point'];
        removeSourceAndLayers(sourceId, layersToRemove);

        if (Object.keys(editAreaOfInterestObject).length > 0) {
            console.log("Inside the Edit Option", editAreaOfInterestObject)
            if (editAreaOfInterestObject.type === "Target Track") {
                const geojson = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [editAreaOfInterestObject.coordinates.long, editAreaOfInterestObject.coordinates.lat],
                    },
                };
                const featureId = drawRef.current.add(geojson);
                drawRef.current.changeMode('simple_select', { featureId });
            } else {
                const geojson = {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [editAreaOfInterestObject.polygon],
                    },
                };
                const featureId = drawRef.current.add(geojson);

                drawRef.current.changeMode('direct_select', { featureId });
            }
        }
        else if (Object.keys(aoiCollection).length > 0) {
            newMap.addSource('aoi-source', {
                type: "geojson",
                data: aoiCollection,
            });

            newMap.addLayer({
                id: 'aoi-polygon',
                type: 'fill',
                source: 'aoi-source',
                paint: {
                    'fill-color': '#090909',
                    'fill-opacity': 0.3,
                },
                filter: [
                    'all',
                    ['==', '$type', 'Polygon'],
                ]
            });

            newMap.addLayer({
                id: 'aoi-polygon-outline',
                type: 'line',
                source: 'aoi-source',
                layout: {},
                paint: {
                    'line-color': '#090909',
                    'line-width': 1
                },
                filter: [
                    'all',
                    ['==', '$type', 'Polygon'],
                ]
            });

            newMap.addLayer({
                id: 'aoi-point',
                type: 'circle',
                source: 'aoi-source',
                paint: {
                    'circle-color': '#CCF54E',
                    'circle-radius': 4,
                },
                filter: ['==', '$type', 'Point']
            });

            // Remove existing click event listener before adding a new one
            newMap.off('click', ['aoi-polygon', 'aoi-point'], featureClickHandler);

            newMap.on('click', ['aoi-polygon', 'aoi-point'], featureClickHandler);
        }
    };

    const showSelectedFeature = () => {
        const newMap = mapRef.current;

        removeSourceAndLayers('highlighted-source',
            ['highlighted-polygon', 'highlighted-point']
        );

        newMap.addSource('highlighted-source', {
            type: "geojson",
            data: {
                type: 'FeatureCollection',
                features: [selectedFeature],
            },
        });

        newMap.addLayer({
            id: 'highlighted-polygon',
            type: 'fill',
            source: 'highlighted-source',
            paint: {
                'fill-color': '#CCF54E',
                'fill-opacity': 0.2
            },
            filter: [
                'all',
                ['==', '$type', 'Polygon'],
            ]
        });

        newMap.addLayer({
            id: 'highlighted-point',
            type: 'circle',
            source: 'highlighted-source',
            paint: {
                'circle-color': '#CCF54E',
                'circle-radius': 5,
            },
            filter: ['==', '$type', 'Point']
        });
    };

    useEffect(() => {
        // Wait for the style to be fully loaded before calling showAoiOnMap
        if (mapRef.current.isStyleLoaded()) {
            showAoiOnMap();
        } else {
            mapRef.current.once("styledata", showAoiOnMap);
        }
    }, [aoiCollection, mapRef.current]);

    useEffect(() => {
        if (Object.keys(selectedFeature).length > 0) {
            // showSelectedFeature();
        } else {
            if (mapRef.current.getSource('highlighted-source'))
                removeSourceAndLayers('highlighted-source',
                    ['highlighted-polygon', 'highlighted-point']
                );
        }
    }, [selectedFeature]);

    return (<div id='map' ref={mapContainerRef} className="position-relative h-100 w-100"></div>)

};

export default MapboxView;