import Select from 'react-select';

interface CustomSelectProps {
    options: any[];
    placeholder: string;
    value: any;
    onChange: (value: any) => void;
    isDisabled?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, placeholder, value, onChange, isDisabled }) => {
    return (
        <Select
            className='w-100'
            isDisabled={isDisabled}
            placeholder={placeholder}
            options={options}
            value={value}
            onChange={onChange}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: "4px",
                    border: "0.5px solid rgba(240, 240, 240, 0.30)",
                    backgroundColor: state.selectProps?.value ? "#000" : "rgba(10, 10, 10, 0.65)",
                }),
                menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#000",
                }),
                option: (provided, state) => {
                    return {
                        ...provided,
                        fontSize: "12px !important",
                        backgroundColor: state.isSelected
                            ? "rgb(49, 53, 36)" // Highlight color for selected option
                            : "rgba(255, 255, 255, 0.15)",
                        color: state.isSelected
                            ? "#CCF54E" // Text color for selected option 
                            : "#FFFFFF", // Default text color
                        "&:hover": {
                            backgroundColor: "rgba(240, 240, 240, 0.20)",
                            color: '#FFFFFF'
                        },

                    };
                },

                placeholder: (provided) => ({
                    ...provided,
                    color: "#57585a !important",
                    fontSize: "12px !important",
                }),
                singleValue: (provided) => ({
                    ...provided,
                    fontSize: "12px",
                    color: "#fff",
                }),
                menuList: (provided) => ({
                    ...provided,
                    maxHeight: "200px", // Set max-height here
                    overflowY: "auto", // Add scroll if items exceed max-height
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    borderRadius: "4px 0px 4px 0px",
                    backgroundColor: "#000",
                }),
            }}
        />
    )
}

export default CustomSelect