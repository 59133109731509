import { FC } from "react";
import './LiveIndicator.scss'
import CustomToolTip from "../../CommonComponents/CustomToolTip";

interface LiveIndicatorProps {
    className?: string;
    is_live: boolean;
    direction: string | boolean;
    onClick: () => void;
}

const LiveIndicator: FC<LiveIndicatorProps> = ({ className, is_live, direction, onClick }) => {
    return (
        <div className={`live__indicator__container ${className}`}>
            <div className={`live_indicator ${is_live && 'live'}`} />
            {direction &&
                <CustomToolTip title="Live" placement='top'>
                    <div className={`direction_indicator`} onClick={onClick}>
                        {direction === 'left' ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                <path d="M0.5 4.59375C3.33333 4.42708 7.50294 3.65625 9.50294 5.15625C10.885 6.19276 11.4548 7.99754 11.5 9.90625M0.5 4.59375L3.5625 1.09375M0.5 4.59375L3.5625 7.65625" stroke="#CCF54E" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                <path d="M11.5 4.59375C8.66667 4.42708 4.49706 3.65625 2.49706 5.15625C1.11505 6.19276 0.545173 7.99754 0.5 9.90625M11.5 4.59375L8.4375 1.09375M11.5 4.59375L8.4375 7.65625" stroke="#CCF54E" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        }
                    </div>
                </CustomToolTip>
            }
        </div>
    )
}

export default LiveIndicator