import useUrlParams from '../../../../hooks/useUrlParams'

import { useEffect, useState } from 'react'
import { ScheduleSvg, SearchSvg } from '../Svgs'
import { toast } from "react-toastify";
import UpcommingContactsModal from './UpcommingContactsModal';
import CommandCenterService from '../../../../../../service/CommandCenterService';
import { CircularProgress } from '@mui/material';
import useContacts from '../../../../hooks/useContacts';
import { get_remaining_time } from '../../planning/utils';
import { ContactStation } from '../../../../hooks/useGroundContact';

interface ExecuteButtonProps {
    postData: (payload: any) => Promise<void>;
}

const FormSubmit: React.FC<ExecuteButtonProps> = ({ postData }) => {
    const { dashboard, scenario_id } = useUrlParams()
    const { contacts } = useContacts()
    const { ongoing_contact } = contacts
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [isExecuteEnabled, setIsExecuteEnabled] = useState<boolean>(false);
    const [isValidJsonThere, setIsValidJsonThere] = useState<boolean>(true);
    const [upcommingContacts, setUpcommingContacts] = useState<{
        loading: boolean;
        contacts: any[];
        selected: ContactStation | null;
    }>({
        loading: false,
        contacts: [],
        selected: null,
    })
    const [selectedContact, setSelectedContact] = useState<any>(null)

    const get_upcomming_gs_contact_details = () => {
        setUpcommingContacts({
            ...upcommingContacts,
            loading: true,
            contacts: [],
        })
        CommandCenterService.getUpcommingGroundContacts(scenario_id, 'all').then((res) => {
            if (res?.['data']) {
                setOpenModal(true)
                setUpcommingContacts({
                    ...upcommingContacts,
                    loading: false,
                    contacts: res?.['data'],
                })
            } else {
                setUpcommingContacts({
                    ...upcommingContacts,
                    loading: false,
                    contacts: [],
                })
            }
        }).catch((error) => {
            toast.error('Failed to get upcomming contact details.', { toastId: 'error' });
            setUpcommingContacts({
                ...upcommingContacts,
                loading: false,
                contacts: [],
            })
        })
    }

    const checkSelections = () => {
        const currentForm = sessionStorage.getItem('currentForm');
        const selectedBusComponent = sessionStorage.getItem('selectedBusComponent');
        const selectedTelecommand = sessionStorage.getItem('selectedTelecommand');
        const selectedPayload = sessionStorage.getItem('selectedPayload');
        const selectedSequences = sessionStorage.getItem('selectedSequences');
        const seqDuration = sessionStorage.getItem('seqDuration');
        const value = sessionStorage.getItem('value');
        const busComponent = selectedBusComponent ? JSON.parse(selectedBusComponent) : null;
        const telecommand = selectedTelecommand ? JSON.parse(selectedTelecommand) : null;
        const payload = selectedPayload ? JSON.parse(selectedPayload) : null;
        const sequences = selectedSequences ? JSON.parse(selectedSequences) : null;
        const duration = seqDuration !== null ? seqDuration : '';
        const rawBytes = sessionStorage.getItem('rawBytes');

        if (currentForm === 'bus') {
            setIsExecuteEnabled(busComponent !== null && telecommand !== null && isValidJsonThere);
        } else if (currentForm === 'payload') {
            if (sequences?.label === 'Other Sequences') {
                setIsExecuteEnabled(payload !== null && sequences !== null && duration !== '' && value !== null);
            } else {
                setIsExecuteEnabled(payload !== null && sequences !== null && duration !== '');
            }
        } else if (currentForm === 'raw') {
            setIsExecuteEnabled(isBase64(rawBytes))
        }
    };

    useEffect(() => {
        checkSelections();
        const handleStorageUpdate = () => {
            checkSelections();
        };

        window.addEventListener('storageUpdate', handleStorageUpdate);
        return () => {
            window.removeEventListener('storageUpdate', handleStorageUpdate);
        };
    }, []);

    useEffect(() => {
        const handleValidJSONEvent = (e) => {
            setIsValidJsonThere(e.detail.buttonOn)
            checkSelections()
            if (!e.detail.buttonOn) {
                setIsExecuteEnabled(false)
            }
        };
        window.addEventListener('validJSONEvent', handleValidJSONEvent);
        return () => {
            window.removeEventListener('validJSONEvent', handleValidJSONEvent);
        };
    }, []);

    useEffect(() => {
        checkSelections()
    }, [isValidJsonThere])

    useEffect(() => {
        const close_modal = () => {
            setOpenModal(false)
        }
        document.addEventListener('click', close_modal)
        return () => {
            document.removeEventListener('click', close_modal)
        }
    }, [])

    const isBase64 = (str) => {
        if (!str || str === '') {
            // toast.error('Please enter the raw input', { toastId: 'json-error' });
            return false;
        }
        const base64Regex = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;

        // Check if string matches the base64 pattern
        if (!base64Regex.test(str)) {
            toast.error('Invalid base64 string', { toastId: 'json-error' });
            return false;
        }
        try {
            // Try decoding the string to see if it's valid
            window.atob(str);
            toast.dismiss('json-error');
            return true;
        } catch (error) {
            toast.error('Invalid base64 string', { toastId: 'json-error' });
            return false;
        }
    };

    const handleExecute = async () => {
        try {
            const formData = JSON.parse(sessionStorage.getItem('formData') || '{}');

            formData[0].LinkId = selectedContact ? selectedContact?.['uplink']?.['acpBandUid'] : '';
            formData[0].ScheduleId = selectedContact ? selectedContact?.['groundScheduleId'] : formData[0].ScheduleId;
            formData[0].Timestamp = selectedContact ? ongoing_contact?.['groundStationId'] === selectedContact?.['groundStationId'] ? 0 : (selectedContact?.['startTime'] * 1000) + 10000 : 0;
            await postData(formData);
            // Success handling
            setSelectedContact(null);
            setUpcommingContacts({ ...upcommingContacts, selected: null });
            const clearEvent = new Event('clearFormFields');
            window.dispatchEvent(clearEvent);
            window.dispatchEvent(new Event('storageUpdate'));
        } catch (error) {
            // Error handling
            console.error('Failed to post data:', error);
            toast.error("Something went wrong while executing the command!", { toastId: 'json-error' });
            setSelectedContact(null);
        }

        {/* This commented code might be needed in the future if commands need to be cleared after execution. */ }
        // sessionStorage.removeItem('selectedBusComponent');
        // sessionStorage.removeItem('selectedTelecommand');
        // sessionStorage.removeItem('selectedPayload');
        // sessionStorage.removeItem('selectedSequences');
        // sessionStorage.removeItem('seqParams');
        // sessionStorage.removeItem('seqPowerReq');
        // sessionStorage.removeItem('seqDuration');
        // sessionStorage.removeItem('formData');
        // sessionStorage.setItem('currentForm', '');
        // setIsExecuteEnabled(false);

    };

    const shoe_gs_list_container = dashboard === 'operate' ? 'show_container' : ''
    const button_text = dashboard === 'operate' ? (!ongoing_contact || (selectedContact && ongoing_contact && ongoing_contact?.['groundStationId'] !== selectedContact?.['groundStationId'])) ? 'Schedule Now' : 'Execute now' : 'Execute now'
    const show_multicontact = (ongoing_contact && upcommingContacts.selected && (upcommingContacts.selected?.['groundStationId'] !== ongoing_contact?.['groundStationId'])) ? true : false

    return (
        <div className='form__submit__section__container'>
            <div className={`contact__gs__list__container ${shoe_gs_list_container}`}>
                {/* {show_multicontact && <div className='badge'>Multiple ground station in contacts </div>} */}
                {ongoing_contact &&
                    <button className={`gs__details__container ${ongoing_contact?.['groundStationId'] === selectedContact?.['groundStationId'] && "selected"}`}
                        onClick={() => { setSelectedContact(ongoing_contact) }}
                    >
                        <div className='d-flex flex-column w-70 align-items-start'>
                            <span className='gs__details__label'>{`${ongoing_contact?.groundStationName}, ${ongoing_contact?.groundStationProperties?.country}`}</span>
                            <span className='gs__details__other__info'>{ongoing_contact?.groundStationProviderName}</span>
                        </div>
                        <div className='d-flex flex-column w-25 align-items-end'>
                            <span className='gs__details__other__info'>{get_remaining_time(ongoing_contact?.['startTime'], ongoing_contact?.['endTime'])}</span>
                            <span className='gs__details__other__info'>{ongoing_contact?.['bandInfo']?.['bandName']}</span>
                        </div>
                    </button>
                }
                {(upcommingContacts.selected && (upcommingContacts.selected?.['groundStationId'] !== ongoing_contact?.['groundStationId'])) &&
                    <button className={`gs__details__container ${upcommingContacts.selected?.['groundStationId'] === selectedContact?.['groundStationId'] && "selected"}`}
                        onClick={() => { setSelectedContact(upcommingContacts.selected) }}
                    >
                        <div className='d-flex flex-column w-70 align-items-start'>
                            <span className='gs__details__label'>{`${upcommingContacts.selected?.groundStationName}, ${upcommingContacts.selected?.groundStationProperties?.country}`}</span>
                            <span className='gs__details__other__info'>{upcommingContacts.selected?.groundStationProviderName}</span>
                        </div>
                        <div className='d-flex flex-column w-25 align-items-end'>
                            <span className='gs__details__other__info'>{get_remaining_time(upcommingContacts.selected?.['startTime'], upcommingContacts.selected?.['endTime'])}</span>
                            <span className='gs__details__other__info'>{upcommingContacts.selected?.['bandInfo']?.['bandName']}</span>
                        </div>
                    </button>
                }
            </div>
            {/* {show_empty_select_gs_button &&
                <button className='empty__select__gs__button'>
                    Select Ground Stations
                </button>
            } */}

            <div className='form__submit__btn__container'>
                <button className={`execute__btn`}
                    onClick={isExecuteEnabled ? handleExecute : undefined}
                    disabled={dashboard !== 'operate' ? !isExecuteEnabled : (!isExecuteEnabled || !selectedContact)}>
                    {button_text}
                </button>
                <div className={`schedule__btn__container ${dashboard === 'operate' && 'show_btn small'}`}>
                    <button
                        disabled={upcommingContacts.loading}
                        className={`schedule__btn`} onClick={(event: any) => {
                            event.stopPropagation();
                            if (openModal) {
                                setOpenModal(false)
                            } else {
                                get_upcomming_gs_contact_details()
                            }
                        }}>
                        {upcommingContacts.loading ? <CircularProgress size={15} /> : <ScheduleSvg />}
                    </button>
                    <UpcommingContactsModal open={openModal}
                        upcommingContacts={upcommingContacts.contacts}
                        selectedContact={selectedContact}
                        setSelectedContact={(contact) => {
                            setUpcommingContacts({ ...upcommingContacts, selected: contact })
                            setSelectedContact(contact);
                            setOpenModal(false)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default FormSubmit