import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";
// import { BASE_APP_URL } from "./ApiService";

const createTrueTwin = (data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin`, data);
};
const deployTrueTwin = (truetwinId, data) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${truetwinId}/deploy`, data);
};

const unDeployTrueTwin = (truetwinId, data) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${truetwinId}/undeploy`, data);
};

const getAllTrueTwins = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin`);
};
const get_TrueTwins_by_satId = (sat_Id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin?satId=${sat_Id}`);
};

const getTrueTwinStatus = (trueTwinId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${trueTwinId}`);
}

const delete_TrueTwin = (trueTwinId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${trueTwinId}`);
};

const delete_Task = (taskId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}task?taskId=${taskId}`);
}
const delete_Schedule = (sechduleId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}schedule?scheduleId=${sechduleId}`);
}

const postJSONCommand = (missionId, satId, jsonData, format) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/test-run?missionId=${missionId}&satId=${satId}&sequenceFormat=${format}`, jsonData);
}
const getJSONResponse = (testId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/test-run/${testId}/status`);
}

const getObject = () => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/test-case`);
}


const connectGroundStation = (gsId, truetwinId, scenario_id) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/connect?groundStationId=${gsId}&truetwinId=${truetwinId}&scenarioId=${scenario_id}`);
}

const removeGroundStation = (gsId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation?groundStationId=${gsId}`);
}

const runSequence = (gsId, format, truetwinId, scenario_id, bandType, type, data) => {
  if ((bandType === null) || (bandType === undefined)) {
    bandType = ""
  }
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/runsequence?groundStationId=${gsId}&sequenceFormat=${format}&trueTwinId=${truetwinId}&scenarioId=${scenario_id}&linkId=${bandType}&mode=${type}`, data);
}

const disconnectGroundStation = (gsId, truetwinId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/disconnect?groundStationId=${gsId}&truetwinId=${truetwinId}`);
}

const downloadSessionLogs = (trueTwinId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${trueTwinId}/techsupport`);
}
const createConsoleSession = (truetwinId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/CreateConsoleSession?truetwinId=${truetwinId}`);
}
const removeConsoleSession = (truetwinId) => {
  return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/StopConsoleSession?truetwinId=${truetwinId}`);
}
const getConsoleSessionLogs = (truetwinId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/GetSessionList?truetwinId=${truetwinId}`);
}
const deleteConsoleSessionLogs = (truetwinId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/DeleteSession?truetwinId=${truetwinId}`);
}

const createDataStoreTT = (truetwinId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/createDataStoreTT?truetwinId=${truetwinId}`);
}
const getTrueTwinDataStoreFiles = (truetwinId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/getDataStoreTTFiles?truetwinId=${truetwinId}`);
}

const getTrueTwinDataStoreFilecontent = (truetwinId, fileName) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/getFileContentDataStoreTTFiles?truetwinId=${truetwinId}&fileName=${fileName}`);
}

const deleteFileTrueTwinDataStore = (truetwinId, fileType, fileName) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/deleteFileContentDataStoreTTFiles?truetwinId=${truetwinId}&FileType=${fileType}&fileName=${fileName}`);
}

const deleteTrueTwinDataStore = (truetwinId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/deleteDataStoreTT?truetwinId=${truetwinId}`);
}


const uploadScenarioSchedule = (scenarioId, trueTwinId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/uploadschedule?scenarioId=${scenarioId}&trueTwinId=${trueTwinId}`);
}

const getOrbitGrafanadata = (scenarioId, pageNo) => {
  console.log("inside grafana data")
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/ADCSTelemetry?scenarioId=${scenarioId}&pageId=${pageNo}`);
}

const postAdcsSdCardData = (body, flatsatId, groundstationId) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/adcs_log/sd_card?flatSatId=${flatsatId}&groundStationId=${groundstationId}`, body);
}
const postUploadFileConsoleFlatSat = (truetwinId, fileName, aapID, mtu, delay, lossDetection, formData) => {
  return ApiService.upload(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/fileUpload?truetwinId=${truetwinId}&fileName=${fileName}&appId=${aapID}&mtu=${mtu}&delay=${delay}&lossDetection=${lossDetection}`, formData);
}

const postUploadFileConsoleFlatSatTele = (scenarioId, truetwinId, formData) => {
  return ApiService.upload(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/telemetryFromOut?scenarioId=${scenarioId}&truetwinId=${truetwinId}`, formData);
}

// NEW SIMULATION APIS //
const getScenarioOrbitParams = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/orbitparameters?scenarioId=${scenarioId}`);
}
const getOnBoardScheduleData = (simulationId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/simulation/onboardschedule?simulationId=${simulationId}`);
}
const getTrueTwinData = (trueTwinId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/truetwin/${trueTwinId}`);
}

const getScenarioData = (scenario_id) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario?scenarioId=${scenario_id}`);
}

const getSimulationStatus = (simulationId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/simulation/status?simulationId=${simulationId}`);
}

const getOribtData = (scenarioId, trueTwinId, propagation) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/orbit?scenarioId=${scenarioId}&trueTwinId=${trueTwinId}&propagation=${propagation}`);
}

const getGroundContacts = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/scenario/groundstations?scenarioId=${scenarioId}`);
}

const getTimeBasedGroundContacts = (scenarioId,startTime, endTime, mode) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${scenarioId}/contacts/time?startTime=${startTime}&endTime=${endTime}&mode=${mode}`);
}
const getUpcommingGroundContacts = (scenarioId, queryMode, startTime, endTime) => {
  if(queryMode === 'all'){
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${scenarioId}/contacts/upcoming?queryMode=${queryMode}`);
  }else{
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${scenarioId}/contacts/upcoming?queryMode=${queryMode}&startTime=${startTime}&endTime=${endTime}`);
  }
}

const getSatelliteById = (satId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}satellite/${satId}`,);
}

const getCommandDetailsById = (truetwinId, satelliteId, scenarioId, commandId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/comms/command?TruetwinId=${truetwinId}&SatelliteId=${satelliteId}&ScenarioId=${scenarioId}&CommandUid=${commandId}`);
};
const getAllCommandDetails = (satelliteId, truetwinId, scenarioId, cursor) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/comms/allCommands?fromTime=${-1}&toTime=${-1}&SatelliteId=${satelliteId}&TruetwinId=${truetwinId}&LinkId=${""}&ScenarioId=${scenarioId}&SessionId=${""}&cursor=${cursor}&size=${50}`);
}
const postCommandDetails = (data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/comms/command`, data);
}

// Operate Relatedd APIS //

const generateOperateSchedule = (payload) => {
  const { scenarioId, taskPlanId, startEpoch, duration, scheduleName } = payload;
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/operate/${scenarioId}/generateonboardschedule?taskPlanId=${taskPlanId}&startEpoch=${startEpoch}&duration=${duration}&scheduleName=${scheduleName}`);
}
const getOngoingSchedule = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/operate/${scenarioId}/getongoingschedule`);
}
const getAllOnBoardSchedules = (scenarioId) => {
  return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/operate/${scenarioId}/onboardschedule/all`);
}
const deleteOnboardSchedules = (scenarioId, scheduleId) => {
  return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/operate/${scenarioId}/onboardschedule/${scheduleId}`);
}
// Operate Relatedd APIS //
// NEW SIMULATION APIS //


const CommandCenterService = {
  createTrueTwin,
  deployTrueTwin,
  unDeployTrueTwin,
  getAllTrueTwins,
  get_TrueTwins_by_satId,
  postJSONCommand,
  getJSONResponse,
  delete_TrueTwin,
  getObject,
  getTrueTwinStatus,
  connectGroundStation,
  removeGroundStation,
  runSequence,
  disconnectGroundStation,
  delete_Task,
  delete_Schedule,
  downloadSessionLogs,
  createConsoleSession,
  removeConsoleSession,
  getConsoleSessionLogs,
  deleteConsoleSessionLogs,
  createDataStoreTT,
  getTrueTwinDataStoreFiles,
  getTrueTwinDataStoreFilecontent,
  deleteFileTrueTwinDataStore,
  deleteTrueTwinDataStore,
  uploadScenarioSchedule,
  getOrbitGrafanadata,
  postAdcsSdCardData,
  postUploadFileConsoleFlatSat,
  postUploadFileConsoleFlatSatTele,

  // NEW SIMULATION APIS //
  getOribtData,
  getTrueTwinData,
  getScenarioData,
  getGroundContacts,
  getTimeBasedGroundContacts,
  getUpcommingGroundContacts,
  getSimulationStatus,
  getScenarioOrbitParams,
  getOnBoardScheduleData,
  getSatelliteById,
  getCommandDetailsById,
  postCommandDetails,
  getAllCommandDetails,
  generateOperateSchedule,
  getOngoingSchedule,
  getAllOnBoardSchedules,
  deleteOnboardSchedules
  // NEW SIMULATION APIS //
};
export default CommandCenterService;
