import { Fragment } from 'react'
import './Schedule.scss'
import { get_navigator_button_by_dashboard } from '../utils'
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip'
import { get_formated_time, get_task_status_icon } from '../../../../utils'
import EmptyPage from '../../../../custom_components/EmptyPage'
import useUrlParams from '../../../../hooks/useUrlParams'
import useContacts from '../../../../hooks/useContacts'
import useUrlNavigation from '../../../../hooks/useUrlNavigation'
import useViewer from '../../../../hooks/useViewer'
import useOnBoardSchedule, { ScheduleData } from '../../../../hooks/useOnBoardSchedule'
import { GroundTaskSvg, PayloadTaskSvg, SubSystemSvg } from '../../modals/Svgs'

const ControlSchedule = () => {
    const { tab_name, dashboard } = useUrlParams()
    const { selectedScheduleTask } = useContacts()
    const { navigate_to } = useUrlNavigation()
    const { current_simulation_time } = useViewer()
    const { scheduleDetails, scheduleData, select_schedule_data } = useOnBoardSchedule()
    return (
        <div className='planning__container__body'>
            {/* {scheduleData?.length > 0 &&
                <Fragment>
                    <div className='planning__container__header'>
                        <div className='planning__header__left__container'>
                            {get_navigator_button_by_dashboard(dashboard!).map((btn) => {
                                return (
                                    <button className={`button ${tab_name === btn.name && 'active'}`}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            e.stopPropagation()
                                            navigate_to(btn.name)
                                        }}
                                    >{btn.label}</button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='schedule__container'>
                        <Fragment>
                            <div className='left__schedule__panel__container'>
                                <div className='left__schedule__panel__container__header'>
                                    <span className='header__title'>
                                        <CustomToolTip title={scheduleDetails.name} placement='top'>
                                            {scheduleDetails.name}
                                        </CustomToolTip>
                                    </span>
                                    <div className='header__time__details'>
                                        <span>{scheduleDetails.start_time && `${get_formated_time(scheduleDetails.start_time)}`}</span>
                                    </div>

                                </div>
                                <div className='right__schedule__panel__container__table__header'>
                                    <span>Tasks</span>
                                    <span>{scheduleData?.length}</span>
                                </div>
                                <div className='right__schedule__panel__container__table__body'>
                                    {scheduleData?.length > 0 && scheduleData.map((task: ScheduleData, index) => {
                                        const current_time = current_simulation_time / 1000
                                        const task_start_time = task.startTime;
                                        const task_end_time = task.endTime;
                                        const task_type_icon = task.type === 'Payload Task' ? <PayloadTaskSvg /> : <GroundTaskSvg />
                                        const task_status_icon = get_task_status_icon(task_start_time, task_end_time, current_time)
                                        const sub_name = task.type === 'Payload Task' ? `${task?.['properties']?.['PayloadName']} | ${task?.['properties']?.['SequenceName']}` : `${task?.['properties']?.['GroundStationName']}`
                                        const task_running = (current_time >= task_start_time && current_time <= task_end_time) ? 'running' : ''

                                        return (
                                            <Fragment>
                                                <div className={`schedule__details__container ${task_running} ${selectedScheduleTask?.schedule_data?.startTime === task?.startTime && 'active'}`}
                                                    onClick={() => select_schedule_data(task)}
                                                >
                                                    <div className='schedule__icons'>
                                                        {task_status_icon}
                                                        {task_type_icon}
                                                    </div>
                                                    <div className='details__container'>
                                                        <span>
                                                            <CustomToolTip title={task?.['taskName']} placement='top'>
                                                                {task?.['taskName']}
                                                            </CustomToolTip>
                                                        </span>
                                                        <span>
                                                            <CustomToolTip title={task?.['taskName']} placement='top'>
                                                                {sub_name}
                                                            </CustomToolTip>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='schedule__details__container__separator' />
                                            </Fragment>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='schedule__panel__separator' />
                            <div className='right__schedule__panel__container'>
                                <div className='right__schedule__panel__container__header'>
                                    <div className='header__title__details__container'>
                                        <div className='header__title__details'>
                                            <div className='d-flex gap-1'>
                                                {selectedScheduleTask?.schedule_data?.type === 'Payload Task' ? <PayloadTaskSvg /> : <GroundTaskSvg />}
                                                <span>{selectedScheduleTask?.schedule_data?.['taskName']}</span>
                                            </div>
                                            <span className='header__subtitle'>{selectedScheduleTask?.schedule_data?.type === 'Payload Task' ? `${selectedScheduleTask?.schedule_data?.['properties']?.['PayloadName']} | ${selectedScheduleTask?.schedule_data?.['properties']?.['SequenceName']}` : `${selectedScheduleTask?.schedule_data?.['properties']?.['GroundStationName']}`}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-3 align-items-center'>
                                        <div className='header_badge'>
                                            <span>{get_formated_time(selectedScheduleTask?.schedule_data?.['startTime'])}</span>
                                        </div>
                                        <span>to</span>
                                        <div className='header_badge'>
                                            <span>{get_formated_time(selectedScheduleTask?.schedule_data?.['endTime'])}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='right__schedule__panel__container__table__header'>
                                    <span>Telecommands</span>
                                    <span>{selectedScheduleTask?.schedule_data?.['commandsList']?.length}</span>
                                </div>
                                <div className='right__schedule__panel__container__table__body'>
                                    {selectedScheduleTask?.schedule_data &&
                                        <Fragment >
                                            <div className={`telecommands__details__container `}>
                                                <div className='telecommands__details__header'>
                                                    <div className='telecommands__details__header__item'> SUBSYSTEM & COMMAND</div>
                                                    <div className='telecommands__details__header__item'>START TIME</div>
                                                    <div className='telecommands__details__header__item'>DURATION</div>
                                                </div>
                                                <div className='telecommands__details__body__container'>
                                                    {selectedScheduleTask?.schedule_data?.['commandsList']?.map((command, id) => {
                                                        const current_time = current_simulation_time / 1000
                                                        const sub_task_start_time = command.epoch;
                                                        const sub_task_end_time = command.epoch + command.duration;
                                                        const sub_task_status_icon = get_task_status_icon(sub_task_start_time, sub_task_end_time, current_time);
                                                        // const sub_task_running = command?.['status'] === 'active' ? 'running' : ''
                                                        const sub_task_running = (current_time >= sub_task_start_time && current_time <= sub_task_end_time) ? 'running' : ''
                                                        return (
                                                            <Fragment key={id}>
                                                                <div className={`telecommands__details__body ${sub_task_running}`}>
                                                                    <div className='telecommands__details__body__item'>
                                                                        <div className='schedule__icons'>{sub_task_status_icon}</div>
                                                                        <div className='details__container'>
                                                                            <div className='command__title__container'>
                                                                                <SubSystemSvg />
                                                                                <span>{command?.['name']}</span>
                                                                            </div>
                                                                            <span>{command?.['subsystem']}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className='telecommands__details__body__item'>
                                                                        <span>{get_formated_time(command?.['epoch'])}</span>
                                                                    </div>
                                                                    <div className='telecommands__details__body__item'>
                                                                        <span>{command?.['duration']} sec</span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        </Fragment>
                    </div>
                </Fragment>
            } */}
            {/* {scheduleData?.length === 0 && */}
            <EmptyPage content='No Control Scheduled' >
                <div className='planning__container__header'>
                    <div className='planning__header__left__container'>
                        {get_navigator_button_by_dashboard(dashboard!).map((btn) => {
                            return (
                                <button className={`button ${tab_name === btn.name && 'active'}`}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        e.stopPropagation()
                                        navigate_to(btn.name)
                                    }}
                                >{btn.label}</button>
                            )
                        })}
                    </div>
                </div>
            </EmptyPage>
            {/* } */}
        </div>
    )
}

export default ControlSchedule