export const DeleteIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <g opacity="0.8">
            <path d="M2 3.33301H10" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.11187 3.33301V9.55523C9.11187 9.99967 8.66743 10.4441 8.22298 10.4441H3.77854C3.33409 10.4441 2.88965 9.99967 2.88965 9.55523V3.33301" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.22266 3.33295V2.44406C4.22266 1.99962 4.6671 1.55518 5.11155 1.55518H6.88932C7.33377 1.55518 7.77821 1.99962 7.77821 2.44406V3.33295" stroke="#F0F0F0" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
        </g>
    </svg>
)
export const MenuIcon = () => (
    < svg xmlns="http://www.w3.org/2000/svg" width="2" height="10" viewBox="0 0 2 10" fill="none" >
        <path d="M1 1.4375C1.31066 1.4375 1.5625 1.18566 1.5625 0.875C1.5625 0.56434 1.31066 0.3125 1 0.3125C0.68934 0.3125 0.4375 0.56434 0.4375 0.875C0.4375 1.18566 0.68934 1.4375 1 1.4375Z" fill="#F0F0F0" stroke="#F0F0F0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 5.5625C1.31066 5.5625 1.5625 5.31066 1.5625 5C1.5625 4.68934 1.31066 4.4375 1 4.4375C0.68934 4.4375 0.4375 4.68934 0.4375 5C0.4375 5.31066 0.68934 5.5625 1 5.5625Z" fill="#F0F0F0" stroke="#F0F0F0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 9.6875C1.31066 9.6875 1.5625 9.43566 1.5625 9.125C1.5625 8.81434 1.31066 8.5625 1 8.5625C0.68934 8.5625 0.4375 8.81434 0.4375 9.125C0.4375 9.43566 0.68934 9.6875 1 9.6875Z" fill="#F0F0F0" stroke="#F0F0F0" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg >
)
export const CloseArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
        <path d="M1.81734 5L6.34008 9.14548C6.44248 9.23935 6.5 9.36667 6.5 9.49943C6.5 9.63219 6.44248 9.75951 6.34008 9.85339C6.23769 9.94726 6.09881 10 5.95401 10C5.8092 10 5.67033 9.94726 5.56793 9.85339L0.660203 5.35396C0.609421 5.30752 0.569131 5.25235 0.541641 5.19161C0.51415 5.13087 0.5 5.06576 0.5 5C0.5 4.93424 0.51415 4.86913 0.541641 4.80839C0.569131 4.74765 0.609421 4.69248 0.660203 4.64604L5.56793 0.146613C5.67033 0.0527382 5.8092 0 5.95401 0C6.09881 0 6.23769 0.0527382 6.34008 0.146613C6.44248 0.240488 6.5 0.367809 6.5 0.500568C6.5 0.633327 6.44248 0.760649 6.34008 0.854524L1.81734 5Z" fill="#CCF54E" />
    </svg>
)