import * as Cesium from "cesium";
import * as satellite from "satellite.js";
import MissionService from "../../../service/MissionService";

// Array of Cesium colors
const cesiumColors = [
    Cesium.Color.ALICEBLUE,
    Cesium.Color.ANTIQUEWHITE,
    Cesium.Color.AQUA,
    Cesium.Color.AQUAMARINE,
    Cesium.Color.AZURE,
    Cesium.Color.BEIGE,
    Cesium.Color.BISQUE,
    Cesium.Color.BLANCHEDALMOND,
    Cesium.Color.BLUE,
    Cesium.Color.BLUEVIOLET,
    Cesium.Color.BROWN,
    Cesium.Color.BURLYWOOD,
    Cesium.Color.CADETBLUE,
    Cesium.Color.CHARTREUSE,
    Cesium.Color.CHOCOLATE,
    Cesium.Color.CORAL,
    Cesium.Color.CORNFLOWERBLUE,
    Cesium.Color.CORNSILK,
    Cesium.Color.CRIMSON,
    Cesium.Color.CYAN,
    Cesium.Color.DARKBLUE,
    Cesium.Color.DARKCYAN,
    Cesium.Color.DARKGOLDENROD,
    Cesium.Color.DARKGRAY,
    Cesium.Color.DARKGREEN,
    Cesium.Color.DARKGREY,
    Cesium.Color.DARKKHAKI,
    Cesium.Color.DARKMAGENTA,
    Cesium.Color.DARKOLIVEGREEN,
    Cesium.Color.DARKORANGE,
    Cesium.Color.DARKORCHID,
    Cesium.Color.DARKRED,
    Cesium.Color.DARKSALMON,
    Cesium.Color.DARKSEAGREEN,
    Cesium.Color.DARKSLATEBLUE,
    Cesium.Color.DARKSLATEGRAY,
    Cesium.Color.DARKTURQUOISE,
    Cesium.Color.DARKVIOLET,
    Cesium.Color.DEEPPINK,
    Cesium.Color.DEEPSKYBLUE,
    Cesium.Color.DIMGRAY,
    Cesium.Color.DODGERBLUE,
    Cesium.Color.FIREBRICK,
    Cesium.Color.FLORALWHITE,
    Cesium.Color.FORESTGREEN,
    Cesium.Color.FUCHSIA,
    Cesium.Color.GAINSBORO,
    Cesium.Color.GHOSTWHITE,
    Cesium.Color.GOLD,
    Cesium.Color.GOLDENROD,
    Cesium.Color.GRAY,
    Cesium.Color.GREEN,
    Cesium.Color.GREENYELLOW,
    Cesium.Color.GREY,
    Cesium.Color.HONEYDEW,
    Cesium.Color.HOTPINK,
    Cesium.Color.INDIANRED,
    Cesium.Color.INDIGO,
    Cesium.Color.IVORY,
    Cesium.Color.KHAKI,
    Cesium.Color.LAVENDER,
    Cesium.Color.LAWNGREEN,
    Cesium.Color.LEMONCHIFFON,
    Cesium.Color.LIGHTBLUE,
    Cesium.Color.LIGHTCORAL,
    Cesium.Color.LIGHTCYAN,
    Cesium.Color.LIGHTGOLDENRODYELLOW,
    Cesium.Color.LIGHTGRAY,
    Cesium.Color.LIGHTGREEN,
    Cesium.Color.LIGHTGREY,
    Cesium.Color.LIGHTPINK,
    Cesium.Color.LIGHTSEAGREEN,
    Cesium.Color.LIGHTSKYBLUE,
    Cesium.Color.LIGHTSLATEGRAY,
    Cesium.Color.LIGHTSTEELBLUE,
    Cesium.Color.LIGHTYELLOW,
    Cesium.Color.LIME,
    Cesium.Color.LIMEGREEN,
    Cesium.Color.LINEN,
    Cesium.Color.MAGENTA,
    Cesium.Color.MAROON,
    Cesium.Color.MEDIUMAQUAMARINE,
    Cesium.Color.MEDIUMBLUE,
    Cesium.Color.MEDIUMORCHID,
    Cesium.Color.MEDIUMPURPLE,
    Cesium.Color.MEDIUMSEAGREEN,
    Cesium.Color.MEDIUMSLATEBLUE,
    Cesium.Color.MEDIUMSPRINGGREEN,
    Cesium.Color.MEDIUMTURQUOISE,
    Cesium.Color.MEDIUMVIOLETRED,
    Cesium.Color.MIDNIGHTBLUE,
    Cesium.Color.MINTCREAM,
    Cesium.Color.MISTYROSE,
    Cesium.Color.MOCCASIN,
    Cesium.Color.NAVAJOWHITE,
    Cesium.Color.NAVY,
    Cesium.Color.OLDLACE,
    Cesium.Color.OLIVE,
    Cesium.Color.OLIVEDRAB,
    Cesium.Color.ORANGE,
    Cesium.Color.ORANGERED,
    Cesium.Color.ORCHID,
    Cesium.Color.PALEGOLDENROD,
    Cesium.Color.PALEGREEN,
    Cesium.Color.PALETURQUOISE,
    Cesium.Color.PALEVIOLETRED,
    Cesium.Color.PAPAYAWHIP,
    Cesium.Color.PEACHPUFF,
    Cesium.Color.PERU,
    Cesium.Color.PINK,
    Cesium.Color.PLUM,
    Cesium.Color.POWDERBLUE,
    Cesium.Color.PURPLE,
    Cesium.Color.RED,
    Cesium.Color.ROSYBROWN,
    Cesium.Color.ROYALBLUE,
    Cesium.Color.SADDLEBROWN,
    Cesium.Color.SALMON,
    Cesium.Color.SANDYBROWN,
    Cesium.Color.SEAGREEN,
    Cesium.Color.SEASHELL,
    Cesium.Color.SIENNA,
    Cesium.Color.SILVER,
    Cesium.Color.SKYBLUE,
    Cesium.Color.SLATEBLUE,
    Cesium.Color.SLATEGRAY,
    Cesium.Color.SNOW,
    Cesium.Color.SPRINGGREEN,
    Cesium.Color.STEELBLUE,
    Cesium.Color.TAN,
    Cesium.Color.TEAL,
    Cesium.Color.THISTLE,
    Cesium.Color.TOMATO,
    Cesium.Color.TURQUOISE,
    Cesium.Color.VIOLET,
    Cesium.Color.WHEAT,
    Cesium.Color.YELLOW,
    Cesium.Color.YELLOWGREEN
];

// Function to get a random color
function getRandomCesiumColor() {
    const randomIndex = Math.floor(Math.random() * cesiumColors.length);
    return cesiumColors[randomIndex];
}
function getRandomEmoji() {
    // Define Unicode ranges for emojis
    const emojiRanges = [
        [0x1F600, 0x1F64F], // Emoticons
        [0x1F900, 0x1F9FF], // Supplemental Symbols and Pictographs
        [0x1FA70, 0x1FAFF], // Symbols and Pictographs Extended-B
    ];

    // Select a random range
    const randomRange = emojiRanges[Math.floor(Math.random() * emojiRanges.length)];

    // Generate a random code point within the selected range
    const randomCodePoint = Math.floor(Math.random() * (randomRange[1] - randomRange[0] + 1)) + randomRange[0];

    // Convert the code point to a string and return as emoji
    return String.fromCodePoint(randomCodePoint);
}
function getEmojiImage(emoji: string, size = 40) {
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');
    if (context) {
        context.font = `${size}px serif`;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(emoji, size / 2, size / 2);
    }

    return canvas.toDataURL(); // Returns the image as a data URL
}

export const get_all_promoted_sat_orbits = (viewer) => {
    try {
        const Tles = [
            "1 25544U 98067A   24316.21060541  .00023146  00000-0  39278-3 0  99932 25544  51.6386 307.0136 0008481 162.7826 197.3453 15.51344621481359",
            "1 59631U 24082A   24316.14776768 -.00003953  00000+0 -87414-4 0  99912 59631  42.9976   2.4540 0000355 267.2624  92.8192 15.40704045 30442",
            "1 60038U 24112A   24316.48473285  .00004102  00000+0  38662-4 0  99992 60038  53.1563 128.6302 0000252 337.6688  22.4319 15.69730023 25503",
            "1 60051U 24112P   24316.76443040 -.00001182  00000+0 -27819-4 0  99902 60051  53.1586 161.4847 0001132  37.6938 322.4141 15.30181870 25082",
            "1 60039U 24112B   24316.47982903  .00004698  00000+0  43587-4 0  99932 60039  53.1562 128.5972 0000849 100.8018 259.3096 15.69722883 25490",
            "1 60040U 24112C   24316.47493108  .00007124  00000+0  63582-4 0  99932 60040  53.1548 128.5655 0001229 126.5553 233.5578 15.69721012 25498",
            "1 60041U 24112D   24316.47002508  .00001396  00000+0  16388-4 0  99912 60041  53.1564 128.5332 0001998 102.8761 257.2480 15.69719143 25082",
            "1 60043U 24112F   24316.77857128 -.00001770  00000+0 -97709-5 0  99972 60043  53.1551 126.8997 0000257 172.7131 187.3891 15.69693844 25552",
            "1 60046U 24112J   24316.19082307 -.00001482  00000+0 -73819-5 0  99912 60046  53.1571 129.6248 0000809  92.7325 267.3786 15.69704338 25469",
            "1 60050U 24112N   24316.74914158 -.00007433  00000+0 -56572-4 0  99932 60050  53.1573 126.7022 0000500 165.8689 194.2343 15.69703318 25551"
        ]
        Tles.forEach((tle) => {
            const line_1 = tle.slice(0, 96);
            const line_2 = tle.slice(69);
            const TLErec = satellite.twoline2satrec(
                line_1,
                line_2
            );
            const totalSeconds = 1440 * 60;  // 90 minutes in seconds
            const timestepInSeconds = 10;   // Interval between samples

            // Set up time for viewer and clock range
            const start = Cesium.JulianDate.fromDate(new Date());
            const stop = Cesium.JulianDate.addSeconds(start, totalSeconds, new Cesium.JulianDate());
            const availability = new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
                start: start,
                stop: stop,
            })]);

            viewer.clock.startTime = start.clone();
            viewer.clock.stopTime = stop.clone();
            viewer.clock.currentTime = start.clone();
            viewer.clock.multiplier = 1;
            viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;

            // Set up sampled position property for smooth orbit path
            const positionsOverTime = new Cesium.SampledPositionProperty();
            let position: Cesium.Cartesian3;

            // Calculate each position over time
            for (let i = 0; i < totalSeconds; i += timestepInSeconds) {
                const time = Cesium.JulianDate.addSeconds(start, i, new Cesium.JulianDate());
                const jsDate = Cesium.JulianDate.toDate(time);

                // Propagate the satellite's position
                const positionAndVelocity = satellite.propagate(TLErec, jsDate);
                const gmst = satellite.gstime(jsDate);

                if (positionAndVelocity.position && typeof positionAndVelocity.position !== 'boolean') {
                    // Convert ECI position to geodetic and then to Cartesian3
                    const geodeticPos = satellite.eciToGeodetic(positionAndVelocity.position, gmst);
                    position = Cesium.Cartesian3.fromRadians(geodeticPos.longitude, geodeticPos.latitude, geodeticPos.height * 1000);

                    // Add the position to the sampled property
                    positionsOverTime.addSample(time, position);
                }
            }

            // Add the path to the viewer as an entity
            viewer.entities.add({
                availability: availability,
                position: positionsOverTime,
                billboard: {
                    eyeOffset: new Cesium.Cartesian3(0, 0, 0),  // Corrected to use Cesium.Cartesian3 constructor
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    image: require('../../command_center/hooks/cesiumImages/selectedSatellite.png'),
                    pixelOffset: new Cesium.Cartesian2(0, 0),  // Corrected to use Cesium.Cartesian2 constructor
                    scale: 0.75,
                    show: true,
                    verticalOrigin: Cesium.VerticalOrigin.CENTER
                },
                path: {
                    show: true,
                    leadTime: 5000,
                    trailTime: 5000,
                    width: 1,
                    resolution: 120, // Resolution for path smoothness
                    material: new Cesium.ColorMaterialProperty(getRandomCesiumColor()),
                }
            });
        })
    } catch (err) {
        console.log(err)
    }
}

export const get_promoted_sat_orbit = (viewer: Cesium.Viewer, sat_id: string, Launch_Type: string) => {
    try {
        MissionService.getLatestOrbitDetails(sat_id, Launch_Type).then((res) => {
            if (res.data) {
                const TLE = res.data.tle;
                const line_1 = TLE.slice(0, 96);
                const line_2 = TLE.slice(69);
                const TLErec = satellite.twoline2satrec(
                    line_1,
                    line_2
                );

                // Define the total time and time step
                const totalSeconds = 1440 * 60;  // 90 minutes in seconds
                const timestepInSeconds = 10;   // Interval between samples

                // Set up time for viewer and clock range
                const start = Cesium.JulianDate.fromDate(new Date());
                const stop = Cesium.JulianDate.addSeconds(start, totalSeconds, new Cesium.JulianDate());
                const availability = new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
                    start: start,
                    stop: stop,
                })]);

                viewer.clock.startTime = start.clone();
                viewer.clock.stopTime = stop.clone();
                viewer.clock.currentTime = start.clone();
                viewer.clock.multiplier = 1;
                viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;

                // Set up sampled position property for smooth orbit path
                const positionsOverTime = new Cesium.SampledPositionProperty();
                let position: Cesium.Cartesian3;

                // Calculate each position over time
                for (let i = 0; i < totalSeconds; i += timestepInSeconds) {
                    const time = Cesium.JulianDate.addSeconds(start, i, new Cesium.JulianDate());
                    const jsDate = Cesium.JulianDate.toDate(time);

                    // Propagate the satellite's position
                    const positionAndVelocity = satellite.propagate(TLErec, jsDate);
                    const gmst = satellite.gstime(jsDate);

                    if (positionAndVelocity.position && typeof positionAndVelocity.position !== 'boolean') {
                        // Convert ECI position to geodetic and then to Cartesian3
                        const geodeticPos = satellite.eciToGeodetic(positionAndVelocity.position, gmst);
                        position = Cesium.Cartesian3.fromRadians(geodeticPos.longitude, geodeticPos.latitude, geodeticPos.height * 1000);

                        // Add the position to the sampled property
                        positionsOverTime.addSample(time, position);
                    }
                }

                // Add the path to the viewer as an entity
                viewer.entities.add({
                    availability: availability,
                    position: positionsOverTime,
                    billboard: {
                        eyeOffset: new Cesium.Cartesian3(0, 0, 0),  // Corrected to use Cesium.Cartesian3 constructor
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                        image: '/assets/selectedSatellite.png',
                        pixelOffset: new Cesium.Cartesian2(0, 0),  // Corrected to use Cesium.Cartesian2 constructor
                        scale: 0.75,
                        show: true,
                        verticalOrigin: Cesium.VerticalOrigin.CENTER
                    },
                    path: {
                        show: true,
                        leadTime: 5000,
                        trailTime: 5000,
                        width: 1,
                        resolution: 120, // Resolution for path smoothness
                        material: new Cesium.ColorMaterialProperty(Cesium.Color.YELLOW),
                    }
                });
            }
        }).catch((err) => {
            console.log(err);
        });


    } catch (err) {
        console.log(err)
    }
}