import React, { useEffect, useState } from 'react'
import * as Cesium from "cesium";
import { useParams } from 'react-router-dom';

import { get_all_promoted_sat_orbits } from '../utils';
import Settings from '../../../command_center/custom_components/Settings';
import ViewerSettings from '../../../CommonComponents/ViewerSettings';
interface VisualizationProps {
  setLoadingScreen: (data) => void;
  promotedSatellites: any[]
}

const Visualization: React.FC<VisualizationProps> = ({ promotedSatellites, setLoadingScreen }) => {

  const params = useParams();
  const { mission_id } = params
  const [operate_viewer, set_operate_viewer] = useState<Cesium.Viewer | null>(null);

  let Imaginary = new Cesium.MapboxStyleImageryProvider({
    styleId: 'dark-v10',
    accessToken: process.env.REACT_APP_MAPBOX_TOKEN!
  });

  useEffect(() => {
    try {
      const viewer = new Cesium.Viewer("operateCesiumContainer", {
        requestRenderMode: true,
        shouldAnimate: true,
        animation: false, // Disable the default animation widget
        baseLayerPicker: false, // Disable the default base layer picker
        timeline: false, // Disable the timeline
        geocoder: false, // Disable the geocoder
        homeButton: false, // Disable the home button
        navigationHelpButton: false, // Disable the navigation help button
        sceneModePicker: false, // Disable the scene mode picker
        selectionIndicator: false, // Disable the selection indicator
        fullscreenButton: false,
        imageryProvider: Imaginary,
      });
      viewer.scene.globe.baseColor = Cesium.Color.TRANSPARENT
      viewer.scene.globe.showGroundAtmosphere = true;
      viewer.clock.currentTime = viewer.clock.startTime;
      viewer.clock.shouldAnimate = true;
      viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
      viewer.scene.screenSpaceCameraController.enableRotate = true;
      viewer.scene.screenSpaceCameraController.enableZoom = true;
      viewer.scene.screenSpaceCameraController.enableTilt = true;
      viewer.scene.screenSpaceCameraController.enableLook = true;
      viewer.scene.highDynamicRange = false;
      viewer.scene.screenSpaceCameraController.inertiaZoom = 40000000;
      viewer.scene.screenSpaceCameraController.maximumZoomDistance = 35500000;
      viewer.scene.morphTo2D(0);
      get_all_promoted_sat_orbits(viewer)
      set_operate_viewer(viewer)
      setTimeout(() => {
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(0, 0, 35500000)
        });

        setLoadingScreen(false);
      }, 1000)

      return () => {
        viewer.destroy();
      };
    } catch (error) {
      console.error("Error initializing Cesium viewer:", error);
    }

  }, []);


  return (
    <div className='w-100 h-100 d-flex z-0 align-items-center justify-content-center position-relative'>
      {/* <div className='h-10 d-flex align-items-center gap-4 position-absolute top-40px z-3 right-50'>
        <div className='w-auto h-auto d-flex flex-column rounded-2 py-3 px-4 bg-black'>
          <div className='w-100 d-flex gap-3'>
            <div className='d-flex gap-2'>
              <span>Storage: </span>
              <span className='color-atmos'>70% </span>
            </div>
            <div>|</div>
            <div className='d-flex gap-2'>
              <span>Task: </span>
              <span className='color-atmos'>70% </span>
            </div>
            <div>|</div>
            <div className='d-flex gap-2'>
              <span>Power: </span>
              <span className='color-atmos'>70% </span>
            </div>
          </div>
          <div>Satellite performance efficiency </div>
        </div>
        <div className='w-auto h-auto d-flex flex-column rounded-2 py-3 px-4 bg-black'>
          <div className='w-100 d-flex gap-4'>
            <div className='color-atmos'>
              {mission_name}
            </div>
            <div className='d-flex gap-2'>
              <span>Ranchio (RNC), USA </span>
              <div>|</div>
              <span>Maneuver </span>
            </div>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-between'>
            <span>Upcoming Event</span>
            <span>in next 15 mins</span>
          </div>
        </div>
      </div> */}
      <div id="operateCesiumContainer" className='w-100 h-100 flex-1' />
      <ViewerSettings viewer={operate_viewer} viewerId={mission_id} />
    </div>
  )
}

export default Visualization