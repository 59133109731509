import { useEffect, useState } from "react"
import * as Cesium from "cesium";
import useViewer from "./useViewer"
import useContacts from "./useContacts"
import useUrlParams from "./useUrlParams";
// Define interfaces for shared nested properties
export interface GroundStationProperties {
    altitude: string;
    country: string;
    freqbands: string[];
    latitude: string;
    location: string;
    longitude: string;
    provider: string;
    rxFreq: object;
    txFreq: object;
}

export interface LinkInfo {
    bandName: string;
    transceiver: string;
    bandId: string;
    acpBandUid: string;
    mqttTopic: string;
}

export interface BandInfo {
    bandName: string;
    rxFreq: any;
    txFreq: any;
}

export interface ContactStation {
    uniqueId: string;
    tenantId: string;
    contactGspId: string;
    satelliteId: string;
    gspSatId: string;
    startTime: number;
    endTime: number;
    duration: number;
    status: string;
    scenarioId: string;
    groundStationId: string;
    groundStationName: string;
    groundStationProperties: GroundStationProperties;
    gspId: string;
    groundStationProviderName: string;
    uplink: LinkInfo;
    downlink: LinkInfo;
    bandInfo: BandInfo;
};


export type GroundContact = {
    "status": string,
    "message": string,
    "groundStations": ContactStation[]
}

interface Pass {
    start: number;
    end: number;
    gsId: string;
}



const useGroundContact = () => {
    const { viewer } = useViewer()
    const { timelineContactData, planingContactData, selectedContact, setSelectedContact, contacts, setContacts } = useContacts()

    const [loading, setLoading] = useState(true)

    const getContactStatus = (contacts: any, currentTime: number) => {
        const currentTimestamp = currentTime; // Convert to seconds
        let all_passes: Pass[] = []
        contacts.map(contact => {
            all_passes.push({
                start: contact?.['startTime'],
                end: contact?.['endTime'],
                gsId: contact?.['groundStationId']
            })
        }
        )
        all_passes.sort((a, b) => a.start - b.start);

        let ongoingContact: Pass | null = null;
        let upcomingContact: Pass[] = [];
        let latestPreviousContact: Pass[] = [];

        for (const pass of all_passes) {
            if (currentTimestamp >= pass.start && currentTimestamp <= pass.end) {
                ongoingContact = pass;
            } else if (currentTimestamp < pass.start) {
                upcomingContact.push(pass);
            } else if (currentTimestamp > pass.end) {
                latestPreviousContact.push(pass);
            }
        }

        const findContact = (pass: Pass | null): ContactStation | null => {
            return pass ? contacts.find(contact => contact.groundStationId === pass.gsId) || null : null;
        }

        return {
            ongoing_contact: findContact(ongoingContact),
            upcoming_contact: findContact(upcomingContact ? upcomingContact?.[0] : null),
            previous_contact: findContact(latestPreviousContact ? latestPreviousContact?.[latestPreviousContact?.length - 1] : null),
        };

    }


    const get_current_contact_details = (current_time: number) => {
        const { ongoing_contact, upcoming_contact, previous_contact } = getContactStatus(timelineContactData, current_time);

        const selected_contact = ongoing_contact
            ? { contact_type: 'Ongoing', contact_data: ongoing_contact }
            : upcoming_contact
                ? { contact_type: 'Upcoming', contact_data: upcoming_contact }
                : previous_contact
                    ? { contact_type: 'Previous', contact_data: previous_contact }
                    : null;

        setContacts({
            previous_contact: previous_contact,
            ongoing_contact: ongoing_contact,
            upcoming_contact: upcoming_contact,
            current_time: current_time,
        })
        setSelectedContact(selected_contact)
        setLoading(false)
    }

    useEffect(() => {
        if (timelineContactData?.length > 0 && viewer) {
            let Interval = setInterval(() => {
                const clock = viewer?.clock;
                if (clock) {
                    const cesium_time = clock.currentTime;
                    if (cesium_time) {
                        const current_time = Cesium.JulianDate.toDate(cesium_time).getTime() / 1000;
                        get_current_contact_details(current_time)
                    }
                }
            }, 1000);
            return () => clearInterval(Interval);
        }
    }, [timelineContactData, selectedContact, contacts, viewer])


    return {
        loading,
        contacts,
        planingContactData,
        timelineContactData,
        selectedContact,
    }
}

export default useGroundContact