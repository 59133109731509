import { FC } from 'react'
import Map from './Map';
import { ContactStation } from '../../../../hooks/useGroundContact';
import CustomToolTip from '../../../../../CommonComponents/CustomToolTip';
import ToggleMapButton from './ToggleMapButton';
import { MaximizeSvg } from '../Svgs';
import { get_contact_pass_details } from '../utils';
interface Props {
    set_show_modal: () => void;
    all_contacts: {
        previous_contact: null | ContactStation,
        ongoing_contact: null | ContactStation,
        upcoming_contact: null | ContactStation
        current_time: null | number,
    };
    selected_contact: {
        contact_type: string;
        contact_data: ContactStation,
    } | any | null
}
const RightSidePanel: FC<Props> = ({ set_show_modal, all_contacts, selected_contact }) => {
    const { previous_contact, ongoing_contact, upcoming_contact, current_time } = all_contacts

    return (
        <div className='ground__container__right__container'>
            <div className='d-flex flex-column gap-2'>
                <div className='contact__details__header'>
                    <span className='contact__details__header__title'>
                        <CustomToolTip title={`${selected_contact?.contact_data?.groundStationName}, ${selected_contact?.contact_data?.groundStationProperties?.country}`} placement='bottom'>
                            {selected_contact?.contact_data?.groundStationName ? `${selected_contact?.contact_data?.groundStationName}, ${selected_contact?.contact_data?.groundStationProperties?.country}` : '--'}
                        </CustomToolTip>
                    </span>
                    <span className='contact__details__header__sub__title'>
                        <CustomToolTip title={`${selected_contact?.contact_data?.groundStationProviderName}`} placement='bottom'>
                            {selected_contact?.contact_data?.groundStationProviderName}
                        </CustomToolTip>
                    </span>
                </div>
                <div className={`contact__status__badge ${selected_contact?.contact_type === 'Ongoing' && 'ongoing'}`}>
                    <span className='badge__title'>{selected_contact?.contact_type}</span>
                    <div className='time__container'>{current_time && get_contact_pass_details(selected_contact?.contact_type, selected_contact?.contact_data, current_time).remaining}</div>
                </div>
            </div>
            <div className='contact__visualization__miniview'>
                <Map selected_contact={selected_contact} />
                <ToggleMapButton className='small' onClick={set_show_modal}>
                    <MaximizeSvg />
                </ToggleMapButton>
            </div>
            <div className='ground__contact__details'>
                <span>Contact Time</span>
                <span>{current_time && get_contact_pass_details(selected_contact?.contact_type, selected_contact?.contact_data, current_time).time}</span>
            </div>
            <div className='ground__contact__details'>
                <span>Duration</span>
                <span>{current_time && get_contact_pass_details(selected_contact?.contact_type, selected_contact?.contact_data, current_time).duration}</span>
            </div>
            <div className='ground__contact__details'>
                <span>Communication Band</span>
                <div className="band">
                    <span className="band">{selected_contact?.contact_data?.['bandInfo']?.['bandName']}</span>
                </div>
            </div>
            <div className='ground__contact__details'>
                <span>Min. Elevation</span>
                <span>{selected_contact?.contact_data?.groundStationProperties?.['minElevation'] || 0}<sup>o</sup></span>
            </div>
            <div className='ground__contact__details__separator' />

            {/* // TODO : // */}
            {/* <div className='ground__contact__task__details__container'>
                <span>Ground Tasks</span>
                <div className='ground__contact__task__details'>
                    <div className='d-flex gap-2'>
                        <TaskCompleteSvg />
                        <span>Telemetry Downlink</span>
                    </div>
                    <span>23 May, 2024 12:10 UTC</span>
                </div>
                <div className='ground__contact__task__details'>
                    <div className='d-flex gap-2'>
                        <TaskOngoingSvg />
                        <span>Status Update</span>
                    </div>
                    <span>23 May, 2024 12:14 UTC</span>
                </div>
                <div className='ground__contact__task__details'>
                    <div className='d-flex gap-2'>
                        <TaskUpcommingSvg />
                        <span>Schedule Upload</span>
                    </div>
                    <span>23 May, 2024 12:17 UTC</span>
                </div>
            </div> */}
            {/* <div className='ground__contact__details__separator' /> */}
            <div className='ground__contact__task__details__container'>
                <span>Contacts</span>
                {upcoming_contact &&
                    <div className={`ground__contact__event__card upcomming`}>
                        <div className='card__left__section'>
                            <span>Upcoming Contact</span>
                        </div>
                        <div className='card__right__section'>
                            <span>{upcoming_contact?.groundStationName}</span>
                            <span className='time_format'>
                                <CustomToolTip title={current_time ? get_contact_pass_details('Upcoming', upcoming_contact, current_time)?.time : ''} placement='top'>
                                    {current_time && get_contact_pass_details('Upcoming', upcoming_contact, current_time)?.time}
                                </CustomToolTip>
                            </span>
                        </div>
                    </div>
                }
                {ongoing_contact &&
                    <div className={`ground__contact__event__card ongoing`}>
                        <div className='card__left__section'>
                            <span>Ongoing Contact</span>
                        </div>
                        <div className='card__right__section'>
                            <span>{ongoing_contact?.groundStationName}</span>
                            <span className='time_format'>
                                <CustomToolTip title={`${current_time && get_contact_pass_details('Ongoing', ongoing_contact, current_time)?.time}`} placement='top'>
                                    {current_time && get_contact_pass_details('Ongoing', ongoing_contact, current_time)?.time}
                                </CustomToolTip>
                            </span>
                        </div>
                    </div>
                }
                {previous_contact &&
                    <div className={`ground__contact__event__card completed`}>
                        <div className='card__left__section'>
                            <span>Previous Contact</span>
                            <div className='contact__status__badge'>Completed</div>
                        </div>
                        <div className='card__right__section'>
                            <span>{previous_contact?.groundStationName}</span>
                            <span className='time_format'>
                                <CustomToolTip title={current_time ? get_contact_pass_details('Previous', previous_contact, current_time)?.time : ''} placement='top'>
                                    {current_time && get_contact_pass_details('Previous', previous_contact, current_time)?.time}
                                </CustomToolTip>
                            </span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default RightSidePanel