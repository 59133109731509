import { useEffect, useState } from "react";
import useUrlParams from "./useUrlParams";
import CommandCenterService from "../../../service/CommandCenterService";
import MissionService from "../../../service/MissionService";

type OrbitParams = {
    "epoch": number,
    "altitude": number,
    "eccentricity": number,
    "inclination": number,
    "TA": number,
    "RAAN": number,
    "AP": number
}

const useOrbitParams = () => {
    const [orbitParams, setOrbitParams] = useState<OrbitParams>({} as OrbitParams);
    const [loading, setLoading] = useState<boolean>(true);
    const { sat_id, scenario_id, dashboard } = useUrlParams()

    const getOrbitParams = () => {
        CommandCenterService.getScenarioOrbitParams(scenario_id).then(res => {
            if (res.data) {
                setOrbitParams(res.data)
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const getLaunchParams = () => {
        MissionService.getLatestOrbitDetails(sat_id, 'Launch').then(res => {
            if (res.data) {
                setOrbitParams(res.data?.['OrbitParameters'])
            }
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (scenario_id && dashboard !== 'operate') {
            getOrbitParams()
        } else if (sat_id && dashboard === 'operate') {
            getLaunchParams()
        }
    }, [scenario_id, sat_id])

    return { loading, orbitParams }
};

export default useOrbitParams;