import { ReactNode, createContext, useEffect, useState } from "react";
import useUrlParams from "../hooks/useUrlParams";
import CommandCenterService from "../../../service/CommandCenterService";
import useError from "../hooks/useError";

type SimulationTime = {
    start_time: number;
    end_time: number;
    duration: number;
}

interface ScenarioContextType {
    scenario: any;
    TrueTwin: any;
    loading: boolean;
    unDeployLoader: boolean | {};
    setUnDeployLoader: (unDeployLoader: boolean | {}) => void;
    setScenario: (scenario: any) => void;
    setTrueTwin: (TrueTwin: any) => void;
    setLoading: (loading: boolean) => void;
    simulation_time: SimulationTime,
    set_simulation_time: (data: SimulationTime) => void;
}
interface ScenarioProviderProps {
    children: ReactNode;
}

export const ScenarioContext = createContext<ScenarioContextType | undefined>(undefined);

const ScenarioProvider = ({ children }: ScenarioProviderProps) => {
    const { scenario_id, truetwin_id, dashboard } = useUrlParams()
    const [scenario, setScenario] = useState<any>(null);
    const [TrueTwin, setTrueTwin] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [unDeployLoader, setUnDeployLoader] = useState<boolean | {}>(false);
    const [simulation_time, set_simulation_time] = useState<SimulationTime>({
        start_time: 0,
        end_time: 0,
        duration: 0
    })

    const { setError } = useError()

    const get_simulation_time = (scenario: any) => {
        if (dashboard === 'operate') {
            const scenario_start_time = scenario?.['scenarioStartTime']
            const scenario_duration = 1440 * 60
            const scenario_end_time = scenario_start_time + scenario_duration
            set_simulation_time({
                start_time: scenario_start_time,
                end_time: scenario_end_time,
                duration: scenario_duration
            })
        } else {
            const simulation_start_time = scenario?.['simulationStartTime']
            const simulation_duration = scenario?.['simulationDuration'] * 60
            const simulation_end_time = simulation_start_time + simulation_duration
            set_simulation_time({
                start_time: simulation_start_time,
                end_time: simulation_end_time,
                duration: simulation_duration
            })
        }
    }

    useEffect(() => {
        if (scenario_id) {
            CommandCenterService.getScenarioData(scenario_id).then(res => {
                if (res?.data) {
                    setScenario(res.data)
                    get_simulation_time(res.data)
                }
            }).catch(err => {
                const error_msg = err?.message
                const error_code = error_msg === 'Network Error' ? error_msg : '500'
                setError(error_code)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [scenario_id])

    useEffect(() => {
        CommandCenterService.getTrueTwinData(truetwin_id).then(res => {
            if (res?.data) {
                setTrueTwin(res.data)
            }
        }).catch((err: any) => {
            const error_msg = err?.message
            const error_code = error_msg === 'Network Error' ? error_msg : '500'
            setError(error_code)
        }).finally(() => {
            setLoading(false)
        })
    }, [truetwin_id])

    return (
        <ScenarioContext.Provider value={{ scenario, loading, setScenario, setLoading, TrueTwin, setTrueTwin, unDeployLoader, setUnDeployLoader, simulation_time, set_simulation_time }}>
            {children}
        </ScenarioContext.Provider>
    )
}

export default ScenarioProvider