import { getEnvVar } from '../envUtils.ts';
import ApiService from "./ApiService";

const postPromoteSatellite = (satelliteId, missionId, data) => {
  return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/promoteToOperate?satId=${satelliteId}&missionId=${missionId}`, data);
};

const updatePromoteSatellite = (trueTwinId, data) => {
    return ApiService.put(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/promoteToOperate?truetwinId=${trueTwinId}`, data);
};

const deletePromoteSatellite = (trueTwinId) => {
    return ApiService.delete(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/promoteToOperate?truetwinId=${trueTwinId}`);
};
const getPromotedSatellite = (trueTwinId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/promoteToOperate?truetwinId=${trueTwinId}`);
};
const getAllPromotedSatellites = (missionId) => {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}test/${missionId}/promoteToOperate`);
};
const getProvider =(satId)=> {
    return ApiService.get(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/GetProvidersBySatelliteId?satId=${satId}`);
};
const addSchedule =(scenarioId,trueTwinId,data)=>{
    return ApiService.post(`${getEnvVar('REACT_APP_BACKEND_BASE_URL')}groundstation/addSchedule?groundStationId=${"G1"}&scenarioid=${scenarioId}&trueTwinId=${trueTwinId}`,data)
}
  

const OperateService = {
    postPromoteSatellite,
    updatePromoteSatellite,
    deletePromoteSatellite,
    getPromotedSatellite,
    getAllPromotedSatellites,
    addSchedule,
    getProvider
};
export default OperateService;
