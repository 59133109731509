import { Fragment, useEffect, useState } from 'react'
import OperateModal from './modal/OperateModal'
import Sidebar from './sidebar/Sidebar'
import Visualization from './visualization/Visualization'
import { useParams } from 'react-router'
import MissionService from '../../../service/MissionService'
import OperateService from '../../../service/OperateService'
import Loader from '../../../Layouts/Loader/Loader'
import { navigateTo } from '../../CommonComponents/CommonFunctions'
import { useNavigate } from "react-router-dom";

const Operate = () => {

  const navigate = useNavigate();
  const params = useParams();
  const { mission_name, mission_id } = params
  const [sidebarLoader, setSidebarLoader] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [fetchOrbitDetails, setFetchOrbitDetails] = useState<any>(false)
  const [loadingScreen, setLoadingScreen] = useState(true);

  const [promotedSatellites, setPromotedSatellites] = useState([])


  const get_all_promoted_satellites = () => {
    OperateService.getAllPromotedSatellites(mission_id)?.then((res) => {
      if (res.data) {
        setPromotedSatellites(res.data)
        setSidebarLoader(false)
      }
    }).catch((err) => {
      setSidebarLoader(false)
      console.error(err);
    })
  }

  const navigate_to_dashboard = (sat_name, sat_id, truetwin_name, truetwin_id, scenario_name, scenario_id) => {
    navigateTo(
      // `${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/operate/${scenario_name}/${scenario_id}/dashboard/operate`,
      `${mission_name}/${mission_id}/${sat_name}/${sat_id}/${truetwin_name}/${truetwin_id}/operate/${scenario_name}/${scenario_id}/hud`,
      navigate
    );
  };

  const calculate_orbit_details = (data: any) => {
    let scenario_id = data?.['scenarioId']
    let scenario_name = data?.['name']
    let sat_name = data?.['name']
    let sat_id = data?.['satId']
    let truetwin_id = data?.['trueTwinId']
    let truetwin_name = data?.['trueTwinDetails']?.['Name']
    navigate_to_dashboard(sat_name, sat_id, truetwin_name, truetwin_id, scenario_name, scenario_id)
  }

  useEffect(() => {
    get_all_promoted_satellites()
  }, [])


  return (
    <Fragment>
      <div className='w-100 d-flex align-items-center justify-content-center h-100 position-relative'>
        <Sidebar
          sidebarLoader={sidebarLoader}
          setSidebarLoader={(data) => setSidebarLoader(data)}
          openModal={(data) => { setOpenModal(data) }}
          promotedSatellites={promotedSatellites}
          fetchPromotedSatellites={() => get_all_promoted_satellites()}
          calculate_orbit_details={(data) => calculate_orbit_details(data)}
        />
        <Visualization
          promotedSatellites={promotedSatellites}
          setLoadingScreen={(data) => setLoadingScreen(data)}
        />
      </div>
      {openModal && <OperateModal
        close={() => {
          setOpenModal(false)
        }}
        navigate_to_operate={get_all_promoted_satellites}
        openModal={openModal}
      />}
      {loadingScreen && <Loader />}
    </Fragment >
  )
}

export default Operate